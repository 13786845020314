// extracted by mini-css-extract-plugin
export var back = "PlasmicBooks-module--back--4b40c";
export var blurb = "PlasmicBooks-module--blurb--d4f1a";
export var copyright = "PlasmicBooks-module--copyright--c09ff";
export var freeBox__bj1M5 = "PlasmicBooks-module--freeBox__bj1M5--61fa6";
export var freeBox__e1Siw = "PlasmicBooks-module--freeBox__e1Siw--31efd";
export var freeBox__gVav3 = "PlasmicBooks-module--freeBox__gVav3--b1c72";
export var freeBox__opqAm = "PlasmicBooks-module--freeBox__opqAm--f7dc3";
export var freeBox__tbJr9 = "PlasmicBooks-module--freeBox__tbJr9--30be0";
export var freeBox__vzDBh = "PlasmicBooks-module--freeBox__vzDBh--ceed2";
export var freeBox__xtBb2 = "PlasmicBooks-module--freeBox__xtBb2--d2d18";
export var h1 = "PlasmicBooks-module--h1--12424";
export var imageBack = "PlasmicBooks-module--imageBack--d7613";
export var imageFront = "PlasmicBooks-module--imageFront--519d1";
export var link = "PlasmicBooks-module--link--6dc31";
export var p = "PlasmicBooks-module--p--357bb";
export var root = "PlasmicBooks-module--root--926e6";
export var text = "PlasmicBooks-module--text--dfd61";